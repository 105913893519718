import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRoutes } from 'routes/AdminRoutes';
import { Header, Sidebar } from 'components';
import { Account } from 'containers';
import { isAuth } from 'services/auth';
import * as RoleManager from 'services/role';
import { getUserProfile } from 'containers/User/UserActions'

import './AdminLayout.scss';
import APIErrorProvider from '../../components/APIErrorNotification/APIErrorProvider';
import { APIErrorNotification } from '../../components/APIErrorNotification/APIErrorNotification';
import { BlockedInfoDialog } from 'components/InfoDialog/BlockedInfoDialog';
import { createHmac } from 'utils/helpers';
import { getBrandPageAsBrandAdmin } from 'repository/brand-page';

declare global {
  interface Window {
    intercomSettings: any;
    Intercom: any;
  }
}

export const AdminLayout = ({ path }) => {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector((state: any) => state.user?.userProfile);
  const [isOpen, setIsOpen] = useState(!(window.outerWidth < 1200));

  const closeSidebar = () => {
    let savedSidebar: any = localStorage.getItem('sidebar');

    if (
      window.outerWidth < 1200 ||
      (savedSidebar && !parseInt(savedSidebar, 10))
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const toggleSidebar = () => {
    localStorage.setItem('sidebar', isOpen ? '0' : '1');
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    closeSidebar();
    window.addEventListener('resize', closeSidebar);

    return () => {
      window.removeEventListener('resize', closeSidebar);
    };
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [])

  useEffect(() => {

    if (!userProfile) {
      return;
    }

    if (RoleManager.isSuperAdmin()) {
      return;
    }

    const fetchBrandPageData = async () => {
      try {
        // Use await for asynchronous operations
        const response = await getBrandPageAsBrandAdmin();
        loadIntercom(response)
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };

    fetchBrandPageData()

    // Function to load the Intercom script
    const loadIntercomScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://widget.intercom.io/widget/fo1ba85d';
      document.head.appendChild(script);

      script.onload = () => {
        if (typeof window.Intercom === "function") {
          window.Intercom('reattach_activator');
          window.Intercom('update', window.intercomSettings);
        }
      };
    };

    const loadIntercom = ({ data }) => {

      // Generate the user hash
      const userHash = createHmac(`${userProfile.id}`);
      
      // Set up Intercom settings
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "fo1ba85d",
        email: userProfile.username,
        user_id: userProfile.id,
        user_hash: userHash,
        company: {
          company_id: `${data.id}`,
          name: `${data.name}`,
        }
      };

      // Load the script
      if (document.readyState === 'complete') {
        loadIntercomScript();
      } else {
        window.addEventListener('load', loadIntercomScript);
      }
    }

    // Clean up the script when the component unmounts
    return () => {
      window.removeEventListener('load', loadIntercomScript);
      // Remove the script element if necessary
      const intercomScript = document.querySelector('script[src="https://widget.intercom.io/widget/fo1ba85d"]');
      if (intercomScript) {
        intercomScript.remove();
      }
    };
  }, [userProfile]);

  if (!isAuth()) {
    return <Redirect to="/login" />;
  }
  
  let isBlocked = !!userProfile?.isBlocked
  let messageType = 'userBlocked'

  const iframePaths = [
    '/dashboard/add-ons',
    '/dashboard/brand-page-banners',
    '/dashboard/information'
  ]

  const isIframePath = iframePaths.includes(location.pathname)

  return (
    <div className="admin">
      <React.Suspense fallback="loading..">
        <Header isOpen={isOpen} toggleSidebar={toggleSidebar} />
      </React.Suspense>
      <React.Suspense fallback="loading..">
        <Sidebar isOpen={isOpen} closeSidebar={closeSidebar} />
      </React.Suspense>

      <React.Suspense fallback="loading..">
        {isBlocked && <BlockedInfoDialog messageType={messageType} allowClose={false} />}
      </React.Suspense>

      <React.Suspense fallback="loading..">
        <div className={`content ${isOpen ? 'content--open' : ''} ${isIframePath && 'padding-0'}`}>
          <APIErrorProvider>
            <APIErrorNotification></APIErrorNotification>
            <Switch>
              {AdminRoutes.map((route: any, index) => {
                if (
                  route.allowed &&
                  RoleManager.isAbleTo(route.namespace, 'view')
                ) {
                  const routeProps = { ...route, path: path + route.path };
                  return (
                    <Route exact {...routeProps} key={route.path + index} />
                  );
                }

                return null;
              })}
            </Switch>
          </APIErrorProvider>
        </div>
      </React.Suspense>
      <Account />
    </div>
  );
};
