export const ROLES = {
  SUPERADMIN: 'ADMIN',
  BRAND_ADMIN: 'BRAND_ADMIN',
  CONTENT_MANAGER: 'CONTENT_MANAGER',
};

export const ROLE_MAP = {
  [ROLES.SUPERADMIN]: {
    admin_menu: {
      view: true,
    },
    user: {
      view: true,
      viewCharts: true,
      postNews: true,
    },
    consumer: {
      view: true,
      viewCharts: true,
      postNews: true,
    },
    brand_page: {
      view: true,
      viewPrivate: true,
      editPricing: true,
      editBrandBanner: true,
      editReachBoost: true,
      create: true,
      delete: true,
      viewShowOnHomePage: true,
      listAll: true
    },
    brand_pages: {
      view: true,
    },
    brand_admin: {
      view: true,
      viewPrivate: true,
      create: true,
      delete: true,
    },
    coupon: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      add_feedback: true,
    },
    category: {
      view: true,
      viewCharts: true,
      postNews: true,
    },
    utility: {
      view: true,
    },
    product: {
      view: true,
    },
    header_images: {
      view: true,
      viewCharts: true,
      postNews: true,
    },
    global_setting: {
      view: true,
      edit: true,
    },
    price_list: {
      view: true,
    },
    brand_users: {
      view: true,
    },
    logout: {
      view: true,
    },
  },
  [ROLES.BRAND_ADMIN]: {
    brand_coupon: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      view_feedback: true,
    },
    brand_page: {
      view: true,
      edit: true,
      editPricing: false,
      create: false,
    },
    brand_account: {
      view: true,
    },
    add_ons: {
      view: true,
    },
    brand_page_banner: {
      view: true,
    },
    information: {
      view: true,
    },
    add_on: {
      view: true,
    },
    logout: {
      view: true,
    },
  },
  [ROLES.CONTENT_MANAGER]: {
    brand_page: {
      view: true,
      viewPrivate: false,
      create: false,
      delete: false,
      listAll: true,
    },
    brand_pages: {
      view: true,
    },
    coupon: {
      view: true,
      viewCharts: true,
      postNews: true,
    },
    utility: {
      view: true,
    },
    logout: {
      view: true,
    },
  },
};
