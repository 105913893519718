import { format } from 'date-fns';
import { HmacSHA256 } from 'crypto-js';

export const isIdValid = (id) => id !== 'create';

export const parseUniqueCodes = (content) =>
  content.split('\n').filter((code) => !!code);

export const formatDateToISOString = (date, time = 'T23:59:59.999Z') => {
  return format(new Date(date), 'yyyy-MM-dd') + time;
};

export const parseRGBAObjectToString = (RGBAobject) => {
  if (!RGBAobject || RGBAobject === '') {
    return '';
  } else {
    return `rgba(${RGBAobject.r}, ${RGBAobject.g}, ${RGBAobject.b}, ${RGBAobject.a})`;
  }
};

export const parseDate = (date) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const objectByString = function (object, pathString: string) {
  function isObject(o) {
    return o === Object(o);
  }

  const pathStringConverted = pathString.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  const pathStringStripped = pathStringConverted.replace(/^\./, ''); // strip a leading dot
  const pathArray = pathStringStripped.split('.');
  for (let i = 0, n = pathArray.length; i < n; ++i) {
    const key = pathArray[i];
    if (isObject(object) && key in object) {
      object = object[key];
    } else {
      return;
    }
  }
  return object;
};

export const isRichTextEmpty = (value) =>
  value === '<p><br></p>' || value === '<p class="ql-align-justify"><br></p>';

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(null, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
};

export const checkDateBetweenDates = (startDate, endDate) => {
  const currentDayMonthYear = parseDate(new Date());
  const startDayMonthYear = parseDate(startDate);
  const endDayMonthYear = parseDate(endDate);
  if (
    new Date(startDayMonthYear) <= new Date(currentDayMonthYear) &&
    new Date(currentDayMonthYear) <= new Date(endDayMonthYear)
  ) {
    return true;
  }
  return false;
};


// random string 
export const createHmac = (userId) => {
  return HmacSHA256(userId, 'MiKBMw8TA-gYwkOijYDQI5hRECMs7b4D3IgZ2fme').toString();
}