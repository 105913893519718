import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import { InputError } from 'components';
import { objectByString } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

interface FormTextFieldProps {
  name: string;
  label: string;
  rules: any;
  className?: string;
  style?: any;
  type?: string;
  step?: number;
  min?: string;
  max?: string;
  helperText?: any;
  onChange?: any;
  hidden?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  variant?: any;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  [key: string]: any;
}

interface FormTextFieldPropsFormValues extends FormTextFieldProps {
  formValues: any;
  errors?: any;
  control?: any;
}

interface FormTextFieldPropsSeparated extends FormTextFieldProps {
  errors: any;
  control: any;
  formValues?: any;
}

export const FormTextField = ({
  name,
  label,
  rules,
  className = '',
  style = {},
  helperText,
  disabled = false,
  onChange: onChangeHandler,
  min,
  max,
  type = 'text',
  step = 1,
  margin = 'normal',
  multiline = false,
  rows = 0,
  variant = 'standard',
  hidden = false,
  formValues,
  errors = formValues.errors,
  control = formValues.control,
  brandInfoTexts = {},
  showRestrictInfo = false,
  ...props
}: FormTextFieldPropsFormValues | FormTextFieldPropsSeparated) => {
  
  const { t }: { t: any } = useTranslation();

  return (
    <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ onBlur, onChange, value, ref }) => (
      <div style={{ position: 'relative' }}>
        <TextField
          label={label}
          fullWidth={true}
          value={value}
          onChange={(event) => {
            onChange(event.target.value);
            onChangeHandler?.(event);
          }}
          onBlur={onBlur}
          inputProps={{
            ...(min ? { min } : {}),
            ...(max ? { max } : {}),
            ...(type === 'number' ? { step } : {}),
          }}
          InputLabelProps={{ shrink: value !== undefined }}
          disabled={disabled}
          className={className}
          style={style}
          multiline={multiline}
          type={type}
          rows={rows}
          variant={variant}
          inputRef={ref}
          margin={margin}
          hidden={hidden}
          helperText={
            helperText ? (
              helperText
            ) : errors && objectByString(errors, name) ? (
              <InputError message={objectByString(errors, name).message} />
            ) : null
          }
          {...props}
        />
        {showRestrictInfo && (
          <div style={{
            display: 'flex', 
            alignItems: 'center', 
            padding: '5px', 
            backgroundColor: '#e2e2e2', 
            borderRadius: '20px',
            position: 'absolute',
            fontSize: '0.8rem',
            bottom: '3px',
            right: '3px',
            height: '1rem',
            fontWeight: 500,
          }}>
              <LockIcon fontSize='small' style={{ marginRight: '5px', color: "#5b5a5a" }} /> 
              {t('expertMode')}
              <IconButton style={{ padding: '0px', marginLeft: '5px' }} onClick={brandInfoTexts['expertModeText']}>
                <InfoIcon fontSize='small' style={{ color: '#208bff' }} />
              </IconButton>
          </div>
        )}
      </div>
    )}
  />
  )
}
