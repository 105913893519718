import API from 'services/api';

export interface ReachBoostUpgradeApproval {
  status: boolean;
  brandPackageId: number;
  startDate: string;
  endDate: string;
}

export const uploadFile = async (file) => {
  return await API.post('/media/upload?entityType=BrandPage', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteFile = async (file) => {
  return await API.delete(`/media/delete?s3FileName=${file}`);
};

export const getBrandPages = (props) => {
  const {
    pageSize = 10,
    page = 0,
    sort = { field: 'id', dir: 'DESC' },
    search = '',
  } = props;
  return API.post('/brands/pages', {
    page,
    pageSize,
    sort: sort.field
      ? [{ field: sort.field, dir: sort.dir.toUpperCase() }]
      : [],
    search,
  });
};

export const getBrandPageAsBrandAdmin = async () => {
  // expected error messages from BE
  // - brandForUserNotFound (with id of user)
  return await API.get('/brand-admin/page');
};

export const createBrandPage = (brandPage: any) => {
  const brandPageData = {
    ...brandPage,
    isPopular: +brandPage.isPopular || false,
  };
  // expected error messages from BE
  // - brandPageIncomplete
  // - brandSlugAlreadyExists
  // - brandNameAlreadyExists
  return API.post('brands', brandPageData);
};

export const deleteBrandPage = (id) => {
  // expected error messages from BE
  // - brandNotFound
  return API.delete(`brands/${id}`);
};

export const getBrandPage = (id) => {
  // expected error messages from BE
  // -  brandNotFound
  return id ? API.get(`/brands/${id}`) : API.get('/brand-admin/page');
};

export const editBrandPage = (id, brandPage) => {
  const brandPageData = {
    ...brandPage,
    isPopular: +brandPage.isPopular || false,
  };
  // expected error messages from BE
  // - brandPageIncomplete
  // - brandSlugAlreadyExists
  // - brandNameAlreadyExists
  return API.put(`/brands/`, {
    id,
    ...brandPageData,
  });
};

export const patchBrandPage = (brandPage) =>
  API.patch('/brand-admin/page', brandPage);

export const getAllBrandPagesMap = () => {
  return API.get('/brands/allBrandsMap');
};

export const getBrandPackageTemplates = () =>
  API.get('/brand-package-templates/list');

export const getBrandPackage = (id) => {
  return id
    ? API.get(`/brand-packages/${id}`)
    : API.get('/brand-admin/page/package');
};
// expected error messages from BE
// - brandPackageNotFound

export const postBrandPackage = (payload) =>
  API.post('/brand-packages', payload);

export const putBrandPackage = (payload) => API.put('/brand-packages', payload);

export const editReachBoostRequest = (reachBoostData) =>
  API.post('/brand-packages/reach-boost', reachBoostData);
// expected error messages from BE
// - reachBoostApproveRequestNotValid
// - noPendingRequestsForApprove
// - invalidRequestBody
